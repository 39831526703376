import Bugsnag from '@bugsnag/js';
import { waitTillAvailable } from '@vp/ab-reader';
import { FulfilmentStrategy } from '@99designs/graph-utils/types';

export async function isMultiStepBriefEnabled(
    productKey: string,
    fulfillmentStrategy?: FulfilmentStrategy
) {
    const available = await waitTillAvailable(1000);
    if (!available) {
        Bugsnag.notify(new Error('AB reader is not available'));
        return false;
    }

    switch (fulfillmentStrategy) {
        case 'FULFILMENT_STRATEGY_CARE':
            return true;
        case 'FULFILMENT_STRATEGY_COMMUNITY':
            return productKey === 'PRD-EVSWZW2XF';
        default:
            return false;
    }
}
