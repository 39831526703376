import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
    ErrorPage,
    FullPageSpinner,
    NotFoundErrorPage,
    PermissionErrorPage,
} from '@99designs/briefs';
import { getCurrentCulture } from '@99designs/design-services-common';
import {
    bugtracker,
    getApolloErrorType,
    useIdentityContext,
} from '@99designs/design-services-common';
import {
    ReviewForm,
    useBriefContext,
    useBriefQuery,
} from '@99designs/feature-design-services-brief';
import { __ } from '@99designs/i18n';
import { useSendPageViewOnce } from '@99designs/tracking';
import { getBriefBaseUrl } from '../lib/getBriefBaseUrl';

export const Review: React.FC = () => {
    const { jobId } = useParams();
    const briefId = jobId as string;
    const locale = useMemo(() => getCurrentCulture(), []);

    function onSubmit(_: unknown, briefId: string) {
        window.location.assign(`${getBriefBaseUrl()}/${briefId}/submit`);
    }

    useSendPageViewOnce(locale);
    const { SignIn, isSignedIn } = useIdentityContext();
    const { product, onCompleted } = useBriefContext();

    const { data, loading, error } = useBriefQuery({
        variables: {
            id: briefId,
        },
        onCompleted,
    });

    if (error) {
        const errorType = getApolloErrorType(error);
        if (errorType === 'UNAUTHENTICATED') {
            if (!isSignedIn) {
                SignIn();
                return <FullPageSpinner />;
            }

            return (
                <ErrorPage
                    title={__(
                        'There was an error loading your brief. Please refresh the page or try again later.'
                    )}
                    error={
                        new Error(
                            `User is logged in on Vistaprint but has no 99designs session: ${error.message}`
                        )
                    }
                    metadata={{ briefId }}
                />
            );
        }

        if (errorType === 'PERMISSION_DENIED') {
            return <PermissionErrorPage />;
        }

        if (errorType === 'NOT_FOUND') {
            return <NotFoundErrorPage />;
        }

        if (errorType === 'UNKNOWN_ERROR') {
            return (
                <ErrorPage
                    error={new Error(`Unknown error loading brief: ${error.message}`)}
                    metadata={{ briefId }}
                />
            );
        }

        return <ErrorPage error={error} />;
    }

    if (loading || !data) {
        return <FullPageSpinner />;
    }

    if (!product) {
        bugtracker.notify(new Error('Brief product is missing from request'), (event) => {
            event.addMetadata('debug info', 'briefId', briefId);
        });

        return <NotFoundErrorPage />;
    }

    return (
        <>
            <Helmet>
                <title>{__('Design Services Brief Review')} | Vistaprint</title>
            </Helmet>
            <ReviewForm onSubmit={onSubmit} brief={data.brief} />
        </>
    );
};
