import { useCallback } from 'react';
import { NotifiableError } from '@bugsnag/js';
import { bugtracker } from '@99designs/design-services-common';
import { FulfilmentStrategy } from '@99designs/graph-utils/types';
import { __url } from '@99designs/i18n';
import { BRIEF_PAGE_NAME, sendAddToCartTrackingEvent } from '@99designs/tracking';
import {
    useCreateRequestWithEventMutation,
    useDesignLiveAppointmentUpdatedMutation,
    useRequestLazyQuery,
    useSubmitRequestMutation,
} from '../../graph';
import { ClaimBriefMutation } from '../../graph/ClaimBrief.generated';
import { UpsertToCartRequest, useCart } from '../../lib/hooks';
import { BriefRequestFragment } from '../BriefForm/brief.generated';
import { DesignServiceRequestType, MERCHANT_SERVICE_CONFIG } from './consts';
import { OrphanedBriefError } from './errors';
import { getWorkIdFromDynamicFields } from './getWorkIdFromDynamicFields';
import { designLiveDataFromBrief, getRequestType } from './requestDataFromBrief';

/*
 *  Note: Experiments are used in `requests` to direct traffic between the new and old stacks
 *  Remove experiments when revision requests are no longer going through MCP,
 *  as there will be no need to send traffic to the old stack anymore
 */

const experiments = JSON.stringify([
    'useRequests',
    // TODO: These experiments are no longer used in Requests anymore so they should be deleted.
    'useCollaborate',
    'useCollaborateForDesignLive',
]);

export const useSubmitToRequests = (
    locale: string,
    accessToken: string,
    shopperId: string,
    briefId: string
) => {
    const [requestQuery] = useRequestLazyQuery();
    const [designLiveAppointmentUpdated] = useDesignLiveAppointmentUpdatedMutation();
    const [createRequestWithEvent] = useCreateRequestWithEventMutation();
    const [submitRequest] = useSubmitRequestMutation();
    const { upsertToCart, requestIdByBriefId: requestIdByBriefIdFromCart } = useCart(locale, {
        accessToken,
        shopperId,
    });

    return useCallback(
        async (data: ClaimBriefMutation) => {
            try {
                const productData = formatProductData(data);

                let expertRequestID = await requestIdByBriefIdFromCart(briefId);
                const hasRequestForBriefInCart = expertRequestID.length > 0;

                if (data.claimStructuredBrief.request) {
                    return await rnbFlow(
                        hasRequestForBriefInCart,
                        submitRequest,
                        designLiveAppointmentUpdated,
                        data.claimStructuredBrief.request,
                        productData,
                        briefId,
                        data,
                        upsertToCart
                    );
                } else {
                    bugtracker.notify(
                        new OrphanedBriefError(
                            `Encountered orphaned brief in useSubmitToRequests hook: ${briefId}`
                        )
                    );
                }

                // todo: delete everything past this point once RnB is fully rolled out

                if (hasRequestForBriefInCart && productData.experience === 'DesignLive') {
                    const { data } = await requestQuery({
                        variables: {
                            id: expertRequestID,
                        },
                    });
                    if (!data) throw new Error(`Failed to call get request`);
                    const request = data.request;

                    if (
                        request.facts?.designLiveAppointmentTime !==
                        productData.briefDesignLiveData.designLiveAppointmentTime
                    ) {
                        const { errors } = await designLiveAppointmentUpdated({
                            variables: {
                                requestId: expertRequestID,
                                input: {
                                    timezone: productData.briefDesignLiveData.timezone!,
                                    appointmentTime:
                                        productData.briefDesignLiveData.designLiveAppointmentTime!,
                                },
                            },
                        });

                        if (errors && errors.length > 0) {
                            throw errors;
                        }
                    }
                }

                if (!hasRequestForBriefInCart) {
                    const purchaseIntentionInitiatedEvent = {
                        withDesignLive: productData.experience === 'DesignLive',
                        ...productData.briefDesignLiveData,
                    };

                    const resp = await createRequestWithEvent({
                        variables: {
                            input: {
                                facts: {
                                    locale,
                                },
                                purchaseIntentionInitiatedEvent,
                            },
                        },
                    });

                    if (!resp.data) throw new Error(`Failed to call create new request`);

                    expertRequestID = resp.data?.createRequestWithEvent?.publicId;

                    sendAddToCartTrackingEvent({
                        pageName: BRIEF_PAGE_NAME,
                        pageStage: 'Design',
                        pageSection: 'Design Services',
                        product_id: productData.expertProductPrd, // should be mpvId
                        name: productData.productName,
                        core_product_id: productData.expertProductPrd,
                        price: productData.productPriceInDecimal,
                        currency: data.claimStructuredBrief.product?.pricing.amount.currency,
                    });
                }

                await submitToCart(productData, briefId, expertRequestID, data, upsertToCart);

                return;
            } catch (e) {
                bugtracker.notify(e as NotifiableError, (event) => {
                    event.context = `Error during submission to requests for: ${briefId}.`;
                });
                throw e;
            }
        },
        [
            requestIdByBriefIdFromCart,
            briefId,
            upsertToCart,
            requestQuery,
            designLiveAppointmentUpdated,
            createRequestWithEvent,
            locale,
            submitRequest,
        ]
    );
};

export async function rnbFlow(
    hasRequestForBriefInCart: boolean,
    submitRequest: ReturnType<typeof useSubmitRequestMutation>[0],
    designLiveAppointmentUpdated: ReturnType<typeof useDesignLiveAppointmentUpdatedMutation>[0],
    request: BriefRequestFragment,
    productData: ReturnType<typeof formatProductData>,
    briefId: string,
    data: ClaimBriefMutation,
    upsertToCart: (payload: UpsertToCartRequest) => Promise<void>
) {
    if (
        productData.experience === 'DesignLive' &&
        request.facts?.designLiveAppointmentTime !==
            productData.briefDesignLiveData.designLiveAppointmentTime
    ) {
        if (
            !productData.briefDesignLiveData.timezone ||
            !productData.briefDesignLiveData.designLiveAppointmentTime
        ) {
            throw new Error(
                `design live data is not sufficient to book an appointment: ${productData.briefDesignLiveData}`
            );
        }
        const { errors } = await designLiveAppointmentUpdated({
            variables: {
                requestId: request.id,
                input: {
                    timezone: productData.briefDesignLiveData.timezone,
                    appointmentTime: productData.briefDesignLiveData.designLiveAppointmentTime,
                },
            },
        });

        if (errors && errors.length > 0) {
            throw errors;
        }
    }
    if (!hasRequestForBriefInCart) {
        const resp = await submitRequest({
            variables: {
                requestId: request.id,
            },
        });
        if (!resp.data) throw new Error(`Failed to call submit request`);

        sendAddToCartTrackingEvent({
            pageName: BRIEF_PAGE_NAME,
            pageStage: 'Design',
            pageSection: 'Design Services',
            product_id: productData.expertProductPrd, // should be mpvId
            name: productData.productName,
            core_product_id: productData.expertProductPrd,
            price: productData.productPriceInDecimal,
            currency: request.expertService?.price.currency,
        });
    }
    await submitToCart(productData, briefId, request.id, data, upsertToCart);
    return;
}

async function submitToCart(
    productData: ReturnType<typeof formatProductData>,
    briefId: string,
    expertRequestID: string,
    data: ClaimBriefMutation,
    upsertToCart: (payload: UpsertToCartRequest) => Promise<void>
) {
    const cartProduct: UpsertToCartRequest['product'] = {
        productKey: productData.expertProductPrd,
        productVersion: productData.cimpressProductVersion,
        productName: productData.productName,
        fulfilmentData: {
            expertRequestID,
            experiments,
            // The following fields are required in the order confirmation UX mod:
            productBriefID: briefId,
            postPaymentUrl: __url('/graphic-design/dashboard'),
            printProductMPVId: data.claimStructuredBrief.fulfilment?.mpvId || '',
            fulfilmentStrategy: data.claimStructuredBrief.fulfilmentStrategy ?? undefined,
            ...(productData.experience
                ? { withDesignLive: productData.experience === 'DesignLive' }
                : {}),
        },
    };

    if (data.claimStructuredBrief.fulfilment?.size) {
        cartProduct.selectedAttributes = [
            {
                value: data.claimStructuredBrief.fulfilment?.size,
                key: 'ProjectSize',
            },
        ];
    }

    // NOTE: Can't use requestID as the correlationID until the brief has a requestID on it (or it's the same ID))
    await upsertToCart({
        product: cartProduct,
        correlationId: briefId,
        workId: getWorkIdFromDynamicFields(data.claimStructuredBrief.dynamicFields),
    });
}

function formatProductData(data: ClaimBriefMutation) {
    const product = data.claimStructuredBrief.product;
    const expertService = data.claimStructuredBrief.request?.expertService;
    const fulfillmentStrategy =
        data.claimStructuredBrief.fulfilmentStrategy ?? product?.fulfilmentStrategy;
    if (!fulfillmentStrategy) {
        throw new Error('Fulfillment strategy is not present in claim structured brief response');
    }
    const productName = expertService?.title ?? product?.title;
    if (!productName) {
        throw new Error('Product name is not present in claim structured brief response');
    }
    const productPriceInDecimal =
        expertService?.price?.listPrice.taxed ??
        Math.round((product?.pricing.amount.amountInCents ?? 0) / 100);
    if (!productPriceInDecimal) {
        throw new Error('Product price is not present in claim structured brief response');
    }
    const cimpressProductKey =
        expertService?.serviceProduct.productKey ??
        product?.cimpressProductConfig?.cimpressProductKey;
    if (!cimpressProductKey) {
        throw new Error('Cimpress product key is not present in claim structured brief response');
    }
    const cimpressProductVersion =
        expertService?.serviceProduct.pimMerchantVersion ??
        product?.cimpressProductConfig?.cimpressProductVersion;
    if (!cimpressProductVersion) {
        throw new Error(
            'Cimpress product version is not present in claim structured brief response'
        );
    }

    const experience = collaborationExperienceForRequest(
        fulfillmentStrategy,
        data.claimStructuredBrief.dynamicFields
    );
    const expertProductPrd = prdForCart(fulfillmentStrategy, cimpressProductKey, experience);
    const briefDesignLiveData = designLiveDataFromBrief(data.claimStructuredBrief.dynamicFields);

    return {
        fulfillmentStrategy,
        productName,
        productPriceInDecimal,
        cimpressProductKey,
        cimpressProductVersion,
        experience,
        expertProductPrd,
        briefDesignLiveData,
    };
}

// TODO: Get the PRD from the parent request!
// Note: this function accounts for the fact that there is one expert product for two PRDs for CARE products
function prdForCart(
    expertType: FulfilmentStrategy,
    defaultPRD: string,
    collaborationExperience: DesignServiceRequestType | null
): string {
    if (expertType === 'FULFILMENT_STRATEGY_COMMUNITY') return 'PRD-EVSWZW2XF'; // FIXME: Temporarily hardcoded for now.
    if (collaborationExperience && MERCHANT_SERVICE_CONFIG[collaborationExperience])
        return MERCHANT_SERVICE_CONFIG[collaborationExperience];

    return defaultPRD;
}

function collaborationExperienceForRequest(
    expertType: FulfilmentStrategy,
    briefData: ClaimBriefMutation['claimStructuredBrief']['dynamicFields']
): DesignServiceRequestType | null {
    switch (expertType) {
        case 'FULFILMENT_STRATEGY_COMMUNITY':
            return null;
        case 'FULFILMENT_STRATEGY_CARE':
            return getRequestType(briefData);
    }
    return null;
}
