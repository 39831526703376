import { DS_BRIEF_BASE_URL } from '@99designs/design-services-common';
import { __url } from '@99designs/i18n';

export function getBriefBaseUrl() {
    if (process.env.NODE_ENV === 'development') {
        return `${
            process.env.NEXT_PUBLIC_APP_HOST || process.env.VITE_NEXT_PUBLIC_APP_HOST
        }${DS_BRIEF_BASE_URL}`;
    }
    return __url(`${DS_BRIEF_BASE_URL}`);
}
