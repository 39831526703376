import Bugsnag from '@bugsnag/js';
import { isFeatureEnabled, waitTillAvailable } from '@vp/ab-reader';

export async function getIsProductOptionsFeatureFlagEnabled() {
    const available = await waitTillAvailable(3000);
    if (!available) {
        Bugsnag.notify(new Error('AB reader is not available'));
        return false;
    }
    return isFeatureEnabled('expert_services_product_options');
}
