import { Box, FlexBox, Typography, Visible, responsive, tokens, tokensRaw } from '@vp/swan';
import styled from 'styled-components';
import { Maybe } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { BottomBar, ProgressBar } from '@99designs/swan-wizard';
import { NormalisedBriefProduct } from '../BriefContext';
import { HowItWorks } from '../HowItWorks/HowItWorks';
import { FieldGroupData } from './FieldGroup';
import { StepProductCard, WorkEntityDisplay } from './StepProductCard';

const ResponsiveFlex = responsive(FlexBox);

const HowItWorksContainer = styled(ResponsiveFlex)`
    justify-content: center;
    align-items: flex-end;
    padding-top: 80px;
    margin-right: 120px;
    margin-left: ${tokens.SwanSemSpace5};

    // margin will adjust dynamically with the viewport
    @media (max-width: ${tokensRaw.SwanBaseBreakpointLgStart}) {
        margin-right: calc(120px - (${tokensRaw.SwanBaseBreakpointLgStart} - 100vw) / 3.5);
    }
`;

export const IntroStep = ({
    product,
    step,
    workEntity,
    group,
}: {
    product: Maybe<NormalisedBriefProduct>;
    step: JSX.Element;
    group: FieldGroupData;
    workEntity?: WorkEntityDisplay;
}) => {
    return (
        <>
            <Box display={'flex'} style={{ justifySelf: 'center' }}>
                <Visible xl lg md>
                    <HowItWorksContainer>
                        <HowItWorks />
                    </HowItWorksContainer>
                </Visible>
                <ResponsiveFlex justifyContent="center" sm={{ py: 9 }} xs={{ pb: 10, pt: 0 }}>
                    <Box mb={4} textAlign="left" style={{ maxWidth: '696px' }}>
                        <ProgressBar />
                        <Box mx={5}>
                            <Typography fontSkin={'title-headline'} mb={3}>
                                {group.hasProductQuestion()
                                    ? __('Tell us about your design project')
                                    : group.title}
                            </Typography>
                            <Typography mb={7}>
                                {__(
                                    'Answer a few short questions to help us design the perfect solution'
                                )}
                            </Typography>
                            <Visible sm xs paddingBottom={3}>
                                <HowItWorks />
                            </Visible>
                            <Box textAlign={'left'} paddingBottom={7}>
                                {product && (
                                    <StepProductCard workEntity={workEntity} product={product} />
                                )}
                            </Box>
                        </Box>
                        {step}
                    </Box>
                </ResponsiveFlex>
            </Box>
            <BottomBar />
        </>
    );
};
