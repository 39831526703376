import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import {
    ErrorPage,
    FullPageSpinner,
    NotFoundErrorPage,
    PermissionErrorPage,
} from '@99designs/briefs';
import { getCurrentCulture } from '@99designs/design-services-common';
import { getApolloErrorType, useIdentityContext } from '@99designs/design-services-common';
import {
    MultiStepBriefForm,
    useBriefContext,
    useBriefQuery,
} from '@99designs/feature-design-services-brief';
import { __ } from '@99designs/i18n';
import { useSendPageViewOnce } from '@99designs/tracking';

export const Brief: React.FC = () => {
    const { jobId } = useParams();
    const briefId = jobId as string;
    const locale = useMemo(() => getCurrentCulture(), []);

    useSendPageViewOnce(locale);
    const { SignIn, isSignedIn } = useIdentityContext();
    const { onCompleted } = useBriefContext();

    const { data, loading, error } = useBriefQuery({
        variables: {
            id: briefId,
        },
        onCompleted,
        errorPolicy: 'all',
    });

    if (error) {
        const errorType = getApolloErrorType(error);
        if (errorType === 'UNAUTHENTICATED') {
            if (!isSignedIn) {
                SignIn();
                return <FullPageSpinner />;
            }

            return (
                <ErrorPage
                    title={__(
                        'There was an error loading your brief. Please refresh the page or try again later.'
                    )}
                    error={
                        new Error(
                            `User is logged in on Vistaprint but has no 99designs session: ${error.message}`
                        )
                    }
                    metadata={{ briefId }}
                />
            );
        }

        if (errorType === 'PERMISSION_DENIED') {
            return <PermissionErrorPage />;
        }

        if (errorType === 'NOT_FOUND') {
            return <NotFoundErrorPage />;
        }

        if (errorType === 'UNKNOWN_ERROR') {
            return (
                <ErrorPage
                    error={new Error(`Unknown error loading brief: ${error.message}`)}
                    metadata={{ briefId }}
                />
            );
        }

        // Ignore 'no product found for mpvID' as mpvID can be empty or incorrect without causing an actual error.
        // ToDo: Remove this check once confident the error no longer occurs.
        if (!error.message.includes('no product found for mpvID')) {
            return <ErrorPage error={error} />;
        }
    }

    if (loading || !data) {
        return <FullPageSpinner />;
    }

    if (!data.brief.fulfilmentStrategy) {
        return (
            <ErrorPage
                title={__(
                    'There was an error loading your brief. Please refresh the page or try again later.'
                )}
                error={new Error(`Fulfilment strategy is missing from request`)}
                metadata={{ briefId }}
            />
        );
    }

    return (
        <>
            <Helmet>
                <title>{__('Design Services Brief')} | Vistaprint</title>
            </Helmet>
            <MultiStepBriefForm brief={data.brief} />
        </>
    );
};
