import { useUpdateField } from '../../useUpdateField';
import { useBriefSetProductDropdownMutation } from './products.generated';
import { useSelectProductWithCacheUpdate } from './useSelectProductMutation';

export function useUpdateProductDropdown(key: string, briefId?: string, initialValue?: string) {
    const { selectProduct } = useSelectProductWithCacheUpdate();

    const [setProductDropdown] = useBriefSetProductDropdownMutation({
        onCompleted: (briefData) => {
            const field = briefData.setField.dynamicFields.find((field) => field.id === key);
            if (!field) {
                return;
            }
            if (field.__typename !== 'ClientConfiguredField') {
                throw new Error(
                    "Field is not a ClientConfiguredField. This schema is not compatible with the fields currently in use on the front end. And will cause all kinds of trouble. If you're seeing this error in bugsnag, investigate immediately"
                );
            }
            const formValue = field.clientConfiguredValue;
            if (briefData.setField.correlationId && formValue) {
                selectProduct({
                    variables: {
                        requestId: briefData.setField.correlationId,
                        input: {
                            printProductMpvId: [formValue],
                        },
                    },
                });
            }
        },
    });

    return useUpdateField({ key, setField: setProductDropdown, briefId, initialValue });
}

export default useUpdateProductDropdown;
