import { useSelectProductMutation } from './selectProduct.generated';

export function useSelectProductWithCacheUpdate() {
    const [selectProduct] = useSelectProductMutation({
        update: (cache, { data }) => {
            if (data && data.addEvent_SelectProduct) {
                cache.modify({
                    id: cache.identify(data.addEvent_SelectProduct),
                    fields: {
                        expertService: () => data.addEvent_SelectProduct.expertService,
                        facts: () => data.addEvent_SelectProduct.facts,
                    },
                });
            }
        },
    });

    return { selectProduct };
}
