import { createContext, useContext, useEffect, useState } from 'react';
import { bugtracker } from '@99designs/design-services-common';
import { useBriefContext } from '../../../../BriefContext';
import { getIsProductOptionsFeatureFlagEnabled } from './getIsProductOptionsFeatureFlagEnabled';
import { usePrintProductConfiguratorLazyQuery } from './product-options.generated';
import type { PrintProductOption } from '@99designs/graph-utils/types';

type PrintProductOptionContextType = {
    availableOptions: PrintProductOption[];
    configuratorLoading: boolean;
    configuratorError: Error | undefined;
    compatibilityMap: Record<string, string[]>;
    setSelectedOptions: (options: Record<string, string>) => void;
    selectedOptions: Record<string, string>;
};

const ProductOptionsContext = createContext<PrintProductOptionContextType | undefined>(undefined);

export function useProductOptions() {
    const context = useContext(ProductOptionsContext);
    if (!context) {
        throw new Error('useProductOptions must be used within a ProductOptionsProvider');
    }
    return context;
}

let isProductOptionsFeatureFlagEnabled: boolean | null = null;

export function ProductOptionsProvider({ children }: { children: React.ReactNode }) {
    const { product } = useBriefContext();

    const [availableOptions, setAvailableOptions] = useState<PrintProductOption[]>([]);
    const [compatibilityMap, setCompatibilityMap] = useState<Record<string, string[]>>({});
    const [selectedOptions, setSelectedOptions] = useState<Record<string, string>>({});
    const [getPrintProductConfiguration] = usePrintProductConfiguratorLazyQuery();
    const [{ configuratorLoading, configuratorError }, setConfiguratorInfo] = useState<{
        configuratorLoading: boolean;
        configuratorError: Error | undefined;
    }>({
        configuratorLoading: true,
        configuratorError: undefined,
    });

    useEffect(() => {
        const fetchFlag = async () => {
            if (isProductOptionsFeatureFlagEnabled == null) {
                isProductOptionsFeatureFlagEnabled = await getIsProductOptionsFeatureFlagEnabled();
            }
            if (product && product.key && product.fulfilmentMpvId) {
                await getPrintProductConfiguration({
                    variables: {
                        input: {
                            mpvId: product.fulfilmentMpvId || '',
                            tenant: 'vistaprint',
                            selectedAttributes: Object.entries(selectedOptions).map(
                                ([key, value]) => ({
                                    attributeKey: key,
                                    valueKey: value,
                                })
                            ),
                            isProductOptionsFeatureFlagEnabled,
                        },
                    },
                    fetchPolicy: 'cache-first',
                    onCompleted: (data) => {
                        setAvailableOptions(data.printProductConfigurator.productOptions);
                        setCompatibilityMap(
                            Object.fromEntries(
                                data.printProductConfigurator.compatibleOptions.map(
                                    ({ key, value }) => [key, value]
                                )
                            )
                        );
                        setConfiguratorInfo((prevState) => ({
                            ...prevState,
                            configuratorLoading: false,
                        }));
                    },
                    onError: (e) => {
                        bugtracker.notify(`Failed to get product configurator: ${e.message}`);
                        setConfiguratorInfo((prevState) => ({
                            ...prevState,
                            configuratorError: e,
                        }));
                    },
                });
            }
        };

        void fetchFlag();
    }, [getPrintProductConfiguration, product, selectedOptions]);

    return (
        <ProductOptionsContext.Provider
            value={{
                availableOptions,
                configuratorLoading,
                configuratorError,
                compatibilityMap,
                setSelectedOptions,
                selectedOptions,
            }}
        >
            {children}
        </ProductOptionsContext.Provider>
    );
}
