import { ChangeEvent, useEffect, useState } from 'react';
import { type UseFormSetValue } from 'react-hook-form';
import {
    Box,
    Dropdown,
    DropdownOption,
    FlexBox,
    FluidImage,
    FormError,
    FormLabel,
    Spinner,
    Typography,
    VisuallyHidden,
} from '@vp/swan';
import styled from 'styled-components';
import type { FieldError, UseFormRegister } from 'react-hook-form';
import { bugtracker } from '@99designs/design-services-common';
import { type PrintProductOption } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useBriefContext } from '../../../../BriefContext';
import { useBriefFormContext } from '../../../../BriefContext/BriefFormContext';
import { type Field_ClientConfiguredField_Fragment } from '../../../brief.generated';
import { ClientConfiguredFieldProps } from '../types';
import { useProductOptions } from './ProductOptionsProvider';
import { findProductOptions } from './index';
import useUpdateProductOptionsDropdown from './useUpdateProductOptionsDropdown';

const DropdownWrapper = styled.div`
    position: relative;
`;

const StyledDropdown = styled(Dropdown)<{ $loading?: boolean }>`
    pointer-events: ${({ $loading }) => ($loading ? 'none' : undefined)};
`;

const FieldWrapper = styled.div<{ opacity?: number }>`
    opacity: ${({ opacity }) => opacity};
`;

export function Input({
    id,
    clientConfiguredValue,
    setValue,
    register,
    error,
}: ClientConfiguredFieldProps) {
    const { product } = useBriefContext();

    const {
        availableOptions,
        configuratorLoading,
        configuratorError,
        compatibilityMap,
        setSelectedOptions,
        selectedOptions,
    } = useProductOptions();

    useEffect(() => {
        setSelectedOptions({});
    }, [product]);

    useEffect(() => {
        if (clientConfiguredValue) {
            const parsedValue = JSON.parse(clientConfiguredValue);
            setSelectedOptions(parsedValue);
        }
    }, [clientConfiguredValue]);

    if (configuratorError || availableOptions.length === 0) {
        return null;
    }

    return (
        <>
            {availableOptions.map((option) => (
                <OptionDropdown
                    key={option.name}
                    option={option}
                    compatibilityMap={compatibilityMap}
                    questionId={id}
                    clientConfiguredValue={clientConfiguredValue}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    setValue={setValue}
                    register={register}
                    error={error}
                    loading={configuratorLoading}
                />
            ))}
        </>
    );
}

interface OptionDropdownProps {
    option: PrintProductOption;
    compatibilityMap: Record<string, string[]>;

    questionId: string;
    clientConfiguredValue: string;

    selectedOptions: Record<string, string>;
    setSelectedOptions: (options: Record<string, string>) => void;

    // TODO: Use actual form types for the following.

    // eslint-disable-next-line
    setValue: UseFormSetValue<any>;
    // eslint-disable-next-line
    register: UseFormRegister<any>;
    error?: FieldError;

    loading: boolean;
}

function OptionDropdown(props: OptionDropdownProps) {
    const {
        option,
        compatibilityMap,
        questionId,
        clientConfiguredValue,
        selectedOptions,
        setSelectedOptions,
        setValue,
        register,
        error,
        loading,
    } = props;

    const { briefId } = useBriefFormContext();
    const updateMutation = useUpdateProductOptionsDropdown(
        questionId,
        briefId,
        clientConfiguredValue
    );

    const isOptionSelected = Object.keys(selectedOptions).includes(option.key);
    const isOptionErrored = error && Object.keys(error as object).includes(option.key);

    const optionRegistration = register(`${questionId}.${option.key}`, {
        required: true,
        onChange: (event) => handleChange(option, event),
    });

    const handleChange = async (
        option: PrintProductOption,
        event: ChangeEvent<HTMLSelectElement>
    ) => {
        const target = event.target.value;

        setValue(`${questionId}.${option}`, target);

        const newSelectedOptions = {
            ...selectedOptions,
            [option.key]: target,
        };

        setSelectedOptions(newSelectedOptions);

        await updateMutation(JSON.stringify(newSelectedOptions));
    };

    const compatibleOptionValues = compatibilityMap[option.key] || [];
    if (!compatibleOptionValues) {
        bugtracker.notify(new Error('no compatible values found for product option'), (event) => {
            event.addMetadata('compatibilityMap', compatibilityMap);
            event.addMetadata('option', option);
        });
    }

    let isOptionValueEmpty = !clientConfiguredValue || clientConfiguredValue === '{}';
    try {
        if (!isOptionValueEmpty) {
            isOptionValueEmpty = JSON.parse(clientConfiguredValue)[option.key] === undefined;
        }
    } catch (e) {
        bugtracker.notify(
            `Can't parse JSON client configured value for product option: ${e}`,
            (event) => {
                event.addMetadata('clientConfiguredValue', [clientConfiguredValue]);
                event.addMetadata('option', [option]);
            }
        );
    }

    return (
        <DropdownWrapper>
            <FieldWrapper opacity={loading && !isOptionSelected ? 0.5 : 1}>
                <FormLabel htmlFor={questionId + option.key} marginBottom={'3'}>
                    {option.name}
                    <Box aria-hidden="true" as="span" ml={'2'}>
                        *
                    </Box>
                    <VisuallyHidden>({__('Required')})</VisuallyHidden>
                </FormLabel>
                {isOptionErrored && (
                    <Box mb={'4'}>
                        <FormError id={`${questionId}.${option.key}+error`} marginTop={'4'}>
                            {__('This field is required')}
                        </FormError>
                    </Box>
                )}
                <StyledDropdown
                    {...optionRegistration}
                    value={selectedOptions[option.key] || ''}
                    ref={(e) => {
                        const element = e as HTMLSelectElement | undefined;
                        if (element && !isOptionErrored) {
                            // This hack removes the error border for individual dropdown, as there may be multiple per question.

                            if (element.className) {
                                element.className = element.className.replace(
                                    'swan-dropdown-skin-error',
                                    ''
                                );
                                element.setAttribute('aria-invalid', 'false');
                            }

                            optionRegistration?.ref(e);
                        }
                    }}
                    fullWidth
                    mb={5}
                    loadingShimmer={loading && !isOptionSelected}
                    $loading={loading}
                    aria-invalid={!!isOptionErrored}
                >
                    <DropdownOption
                        key={`${option.key}-empty`}
                        value=""
                        hidden={!isOptionValueEmpty}
                        disabled
                    >
                        {__('Select {{optionTitle}}', {
                            optionTitle: option.summaryName || option.name,
                        })}
                    </DropdownOption>
                    {option.values.map((value) => (
                        <DropdownOption
                            key={`${option.key}-${value.key}`}
                            value={value.key}
                            disabled={!compatibleOptionValues.includes(value.key)}
                        >
                            {value.name}
                        </DropdownOption>
                    ))}
                </StyledDropdown>
            </FieldWrapper>
        </DropdownWrapper>
    );
}

export function Review(_props: Field_ClientConfiguredField_Fragment) {
    const { availableOptions, selectedOptions, configuratorLoading, configuratorError } =
        useProductOptions();
    const [customerSelectedOptions, setCustomerSelectedOptions] = useState<PrintProductOption[]>(
        []
    );

    useEffect(() => {
        const matchedProductOptions = availableOptions.filter((option) => {
            return Object.keys(selectedOptions).includes(option.key);
        });

        setCustomerSelectedOptions(findProductOptions(selectedOptions, matchedProductOptions));
    }, [availableOptions, selectedOptions]);

    if (configuratorLoading) {
        return <Spinner accessibleText={__('Loading...')} />;
    }

    if (configuratorError || customerSelectedOptions.length === 0) {
        return null;
    }

    return (
        <Box>
            {customerSelectedOptions.map((option) => {
                return (
                    <Box key={`review-option-${option.key}`} mb={5}>
                        <Typography fontSkin="body-standard-bold" mb={4}>
                            {option.name}
                        </Typography>
                        <FlexBox alignItems="center">
                            {option.values[0].imageUrl && (
                                <FluidImage
                                    src={option.values[0].imageUrl}
                                    alt={option.values[0].name}
                                    mr={4}
                                    style={{
                                        width: 'auto',
                                        height: '48px',
                                        borderRadius: '4px',
                                    }}
                                />
                            )}
                            <Box>
                                <Typography
                                    fontSkin={
                                        option.values[0].imageUrl
                                            ? 'body-standard-bold'
                                            : 'body-standard'
                                    }
                                >
                                    {option.values[0].name}
                                </Typography>
                            </Box>
                        </FlexBox>
                    </Box>
                );
            })}
        </Box>
    );
}
