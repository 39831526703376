import { useProductOptions } from '../FormFields/ClientConfiguredFields/ProductOptions/ProductOptionsProvider';
import { Field_WorkEntityField_Fragment, StructuredBriefFragment } from '../brief.generated';
import { FieldGroupData, fieldGroups } from './FieldGroup';

export type WorkEntityDisplay = {
    title: string;
    description: string | null;
    field: Field_WorkEntityField_Fragment;
};

// Should the back end not be returning the data in the correct format for the front end?
// Does this not invalidate the backend driven UI pattern of the form?
// Yes to both questions. The backend driven UI pattern was more useful on the 99d site when there were multiple consumers and many more briefs.
// Here we have made the conscious decision to break that pattern in order to allow greater customisation and control over the form.
// This is not ideal and will be refactored in the future, and we will decide to go either in the direction of fully custom forms on the front end
// or a form builder driven primarily by the back end with limited UI customisation.

export function useFormGroups(brief: StructuredBriefFragment): {
    intro: FieldGroupData;
    groups: FieldGroupData[];
    workEntityField: WorkEntityDisplay | undefined;
} {
    const { availableOptions } = useProductOptions();

    let workEntityField: WorkEntityDisplay | undefined;
    const [intro, ...groups] = fieldGroups(brief)
        .filter((g) => {
            const we = formatWorkEntity(g);
            if (we) {
                workEntityField = we;
                return false;
            }
            if (
                g.fields.length === 0 ||
                (availableOptions.length === 0 && g.id === 'productConfiguration')
            ) {
                return false;
            }
            return true;
        })
        .map((g, i) => new FieldGroupData(g.id, g.title, g.description, g.fields, i === 0));
    return { intro, groups, workEntityField };
}

function formatWorkEntity(fieldGroupData: FieldGroupData) {
    const field = fieldGroupData.fields
        .flatMap((f) => (f.__typename === 'WorkEntityField' ? [f] : []))
        .pop();
    return field
        ? {
              title: fieldGroupData.title,
              description: fieldGroupData.description,
              field,
          }
        : undefined;
}
