import React, { ReactNode, createContext, useContext, useState } from 'react';
import { RecentlySelectedProduct } from '../BriefForm/FormFields/ClientConfiguredFields/ProductDropdown/utils';
import {
    BriefProductFragment,
    BriefQuery,
    BriefRequestFragment,
} from '../BriefForm/brief.generated';

const MAX_RECENT_PRODUCTS = 16;

export type NormalisedBriefProduct = Omit<
    BriefProductFragment,
    'fulfilmentStrategy' | 'name' | '__typename' | 'pricing'
> & {
    pricing: Omit<BriefProductFragment['pricing'], 'earningsInUsd'>;
};

interface BriefContextProps {
    product?: NormalisedBriefProduct;
    updateProduct: (product?: NormalisedBriefProduct) => void;
    onCompleted: (data: BriefQuery) => void;
    recentProducts?: RecentlySelectedProduct[];
    addRecentProduct: (product: RecentlySelectedProduct) => void;
    isUpdatingProduct: boolean;
    setIsUpdatingProduct: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BriefContext = createContext<BriefContextProps | undefined>(undefined);

interface BriefProviderProps {
    children: ReactNode;
}

export const BriefProvider: React.FC<BriefProviderProps> = ({ children }) => {
    const [product, setProduct] = useState<NormalisedBriefProduct>();

    // TODO: Recent products should be stored in the browser's local storage.

    const [recentProducts, setRecentProducts] = useState<RecentlySelectedProduct[]>([]);

    const updateProduct = (newProduct?: NormalisedBriefProduct) => {
        setProduct(newProduct);
    };

    const [isUpdatingProduct, setIsUpdatingProduct] = useState(false);

    return (
        <BriefContext.Provider
            value={{
                product,
                updateProduct,
                recentProducts,
                addRecentProduct: (product: RecentlySelectedProduct) => {
                    setRecentProducts((prev) => {
                        if (prev.length >= MAX_RECENT_PRODUCTS) {
                            prev.splice(MAX_RECENT_PRODUCTS - 1, 1);
                        }

                        const index = prev.findIndex((p) => p.mpvId === product.mpvId);

                        if (index !== -1) {
                            prev.splice(index, 1);
                        }

                        return [product, ...prev];
                    });
                },
                onCompleted: (data: BriefQuery) => {
                    // The product should not update if it already exists as it may be newer than the cached
                    // data fetched from this query.

                    if (product || !data) {
                        return;
                    }

                    if (data.brief.product && data.brief.fulfilment) {
                        updateProduct({
                            title: data.brief.product.title,
                            details: data.brief.product.details,
                            pricing: data.brief.product.pricing,
                            key: data.brief.fulfilment.productKey,
                            fulfilmentMpvId: data.brief.fulfilment.mpvId,
                        });
                    }
                    const es = data.brief.request?.expertService;
                    const esPrd = expertServiceToProduct(es);
                    if (esPrd) {
                        updateProduct(esPrd);
                    }
                },
                isUpdatingProduct,
                setIsUpdatingProduct,
            }}
        >
            {children}
        </BriefContext.Provider>
    );
};

export function expertServiceToProduct(
    es?: BriefRequestFragment['expertService']
): Omit<BriefProductFragment, '__typename' | 'fulfilmentStrategy' | 'name'> | null {
    if (!es || !es.printProduct) return null;

    return {
        title: es.title,
        details:
            es.productDetails?.inclusions.map((i) => ({
                __typename: 'ProductPackageDetails',
                title: i.description,
            })) ?? [],
        pricing: {
            __typename: 'FixedPrice',
            amount: {
                __typename: 'Money',
                amountInCents: Math.round(es.price.listPrice.taxed * 100),
                currency: es.price.currency,
            },
            earningsInUsd: 0,
        },
        key: es.printProduct?.mpvId,
        fulfilmentMpvId: es.printProduct.mpvId,
    };
}

export const useBriefContext = () => {
    const context = useContext(BriefContext);

    if (!context) {
        throw new Error('useBriefContext must be used within a BriefProvider');
    }

    return context;
};
