/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';
import { SetFieldResultFragment } from '../DesignLiveAppointmentV1/appointments.generated';

export type PrintProductConfiguratorQueryVariables = Types.Exact<{
    input: Types.PrintProductConfiguratorInput;
}>;

export type PrintProductConfiguratorQuery = {
    __typename: 'Query';
    printProductConfigurator: {
        __typename: 'PrintProductConfigurator';
        productOptions: Array<{
            __typename: 'PrintProductOption';
            key: string;
            name: string;
            summaryName: string;
            values: Array<{
                __typename: 'ProductOptionValue';
                key: string;
                name: string;
                isCompatible: boolean;
                imageUrl: Types.Maybe<string>;
            }>;
        }>;
        customValues: Array<{
            __typename: 'CustomValue';
            key: string;
            name: string;
            unit: string;
            ranges: Array<{
                __typename: 'ProductOptionRange';
                minimum: number;
                maximum: number;
                increment: number;
            }>;
        }>;
        compatibleOptions: Array<{
            __typename: 'CompatibleOptionsEntry';
            key: string;
            value: Array<string>;
        }>;
    };
};

export type BriefSetProductOptionsDropdownMutationVariables = Types.Exact<{
    briefId: Types.Scalars['ID'];
    key: Types.Scalars['String'];
    value: Types.Scalars['String'];
}>;

export type BriefSetProductOptionsDropdownMutation = {
    __typename: 'Mutation';
    setField: { __typename: 'StructuredBrief' } & SetFieldResultFragment;
};

export const PrintProductConfiguratorDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PrintProductConfigurator' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PrintProductConfiguratorInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'printProductConfigurator' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'productOptions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'summaryName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'values' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'key' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isCompatible',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'imageUrl',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customValues' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'unit' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'ranges' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'minimum',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'maximum',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'increment',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'compatibleOptions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};

/**
 * __usePrintProductConfiguratorQuery__
 *
 * To run a query within a React component, call `usePrintProductConfiguratorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintProductConfiguratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintProductConfiguratorQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintProductConfiguratorQuery(
    baseOptions: Apollo.QueryHookOptions<
        PrintProductConfiguratorQuery,
        PrintProductConfiguratorQueryVariables
    >
) {
    return Apollo.useQuery<PrintProductConfiguratorQuery, PrintProductConfiguratorQueryVariables>(
        PrintProductConfiguratorDocument,
        baseOptions
    );
}
export function usePrintProductConfiguratorLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        PrintProductConfiguratorQuery,
        PrintProductConfiguratorQueryVariables
    >
) {
    return Apollo.useLazyQuery<
        PrintProductConfiguratorQuery,
        PrintProductConfiguratorQueryVariables
    >(PrintProductConfiguratorDocument, baseOptions);
}
export type PrintProductConfiguratorQueryHookResult = ReturnType<
    typeof usePrintProductConfiguratorQuery
>;
export type PrintProductConfiguratorLazyQueryHookResult = ReturnType<
    typeof usePrintProductConfiguratorLazyQuery
>;
export type PrintProductConfiguratorQueryResult = Apollo.QueryResult<
    PrintProductConfiguratorQuery,
    PrintProductConfiguratorQueryVariables
>;
export const BriefSetProductOptionsDropdownDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'briefSetProductOptionsDropdown' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'briefId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'setField' },
                        name: { kind: 'Name', value: 'briefSetClientConfigured' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'briefId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'briefId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'key' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'key' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'value' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'value' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SetFieldResult' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SetFieldResult' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'StructuredBrief' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'correlationId' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dynamicFields' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'Field' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'errors' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'ValidationError' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Field' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DynamicField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'InputField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultiInputField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextAreaField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SelectChoiceField' } },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MultiSelectChoiceField' },
                    },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RadioChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BudgetField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MultiChoiceField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DateField' } },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FilesField' } },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PrintServiceOptionsField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'MultiGridChoiceField' },
                    },
                    {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ClientConfiguredField' },
                    },
                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'WorkEntityField' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'InputField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'InputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'InputField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'helperText' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'inputValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxLength' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'descriptiveText' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiInputField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiInputField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiInputField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'multiInputValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TextAreaField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TextAreaField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TextAreaField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'textAreaValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxLength' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SelectChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SelectChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiSelectChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiSelectChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiChoiceValues' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'GridChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GridChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'RadioChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'RadioChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RadioChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'choiceValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'view' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BudgetField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BudgetField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'BudgetField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiChoiceField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MultiChoiceField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiChoiceValue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'DateField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DateField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'DateField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'dateValue' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FilesField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'FilesField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'FilesField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allowedFileTypes' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'descriptiveText' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filesValue' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'bucket' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'previewable' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'size' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'downloadUrl' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'previewUrl' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SquarespacePackageChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'LogoContestTierChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ContestUpgradesChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'PrintServiceOptionsField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'PrintServiceOptionsField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'MultiGridChoiceField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MultiGridChoiceField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'MultiGridChoiceField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'placeholder' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'multiGridChoiceValue' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'subtext' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'maxSelections' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'validationPrompt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'options' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'subtext' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'reviewImage' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ClientConfiguredField' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ClientConfiguredField' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ClientConfiguredField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'questionId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'clientConfiguredValue' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'WorkEntityField' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkEntityField' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'InlineFragment',
                        typeCondition: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'WorkEntityField' },
                        },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'workEntityValue' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'immutable' } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'ValidationError' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'StructuredBriefValidationError' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                ],
            },
        },
    ],
};
export type BriefSetProductOptionsDropdownMutationFn = Apollo.MutationFunction<
    BriefSetProductOptionsDropdownMutation,
    BriefSetProductOptionsDropdownMutationVariables
>;

/**
 * __useBriefSetProductOptionsDropdownMutation__
 *
 * To run a mutation, you first call `useBriefSetProductOptionsDropdownMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBriefSetProductOptionsDropdownMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [briefSetProductOptionsDropdownMutation, { data, loading, error }] = useBriefSetProductOptionsDropdownMutation({
 *   variables: {
 *      briefId: // value for 'briefId'
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useBriefSetProductOptionsDropdownMutation(
    baseOptions?: Apollo.MutationHookOptions<
        BriefSetProductOptionsDropdownMutation,
        BriefSetProductOptionsDropdownMutationVariables
    >
) {
    return Apollo.useMutation<
        BriefSetProductOptionsDropdownMutation,
        BriefSetProductOptionsDropdownMutationVariables
    >(BriefSetProductOptionsDropdownDocument, baseOptions);
}
export type BriefSetProductOptionsDropdownMutationHookResult = ReturnType<
    typeof useBriefSetProductOptionsDropdownMutation
>;
export type BriefSetProductOptionsDropdownMutationResult =
    Apollo.MutationResult<BriefSetProductOptionsDropdownMutation>;
export type BriefSetProductOptionsDropdownMutationOptions = Apollo.BaseMutationOptions<
    BriefSetProductOptionsDropdownMutation,
    BriefSetProductOptionsDropdownMutationVariables
>;
