import { PropsWithChildren } from 'react';
import {
    FieldError,
    UseFormClearErrors,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
} from 'react-hook-form';
import {
    Box,
    FormError,
    FormField,
    FormInputGroup,
    FormLabel,
    FormLabelOptional,
    Typography,
    VisuallyHidden,
    tokensRaw,
} from '@vp/swan';
import styled from 'styled-components';
import { __ } from '@99designs/i18n';
import { FieldViewSkinType } from '../../../context';
import { FieldViewSkinContext, useFieldViewSkin } from '../../../context/FieldViewContext';
import { FormInput } from '../BriefForm';
import {
    Input as ClientConfiguredField,
    Review as ClientConfiguredFieldReview,
} from '../FormFields/ClientConfiguredField';
import { Input as DateField, View as DateFieldView } from '../FormFields/DateField/DateField';
import {
    Input as FilesField,
    Review as FilesFieldReview,
    View as FilesFieldView,
} from '../FormFields/FilesField';
import {
    Input as GridChoiceField,
    Review as GridChoiceFieldReview,
    View as GridChoiceFieldView,
} from '../FormFields/GridChoiceField/GridChoiceField';
import { Input as InputField, View as InputFieldView } from '../FormFields/InputField';
import {
    Input as MultiChoiceField,
    View as MultiChoiceFieldView,
} from '../FormFields/MultiChoiceField/MultiChoiceField';
import {
    Input as MultiGridChoiceField,
    Review as MultiGridChoiceFieldReview,
    View as MultiGridChoiceFieldView,
} from '../FormFields/MultiGridChoiceField/MultiGridChoiceField';
import {
    Input as MultiInputField,
    View as MultiInputFieldView,
} from '../FormFields/MultiInputField';
import {
    Input as RadioChoiceField,
    View as RadioChoiceFieldView,
} from '../FormFields/RadioChoiceField/RadioChoiceField';
import {
    Input as SelectChoiceField,
    View as SelectChoiceFieldView,
} from '../FormFields/SelectChoiceField/SelectChoiceField';
import { Input as TextAreaField, View as TextAreaFieldView } from '../FormFields/TextAreaField';
import { WorkEntityField } from '../FormFields/WorkEntityField';
import { ViewContextProps, useIsReviewMode, useViewContext } from '../ViewContext';
import { FieldFragment } from '../brief.generated';
import { useIsHiddenInReview } from './useIsHiddenInReview';

type FieldProps = {
    field: FieldFragment;
    error: FieldError | undefined;
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
    clearErrors: UseFormClearErrors<FormInput>;
    setError: UseFormSetError<FormInput>;
    isMultiFieldGroup?: boolean;
    isIntro?: boolean;
};

interface RequiredIndicatorProps {
    isRequired: boolean;
    isOmitted?: boolean;
}

function RequiredIndicator(props: RequiredIndicatorProps) {
    if (props.isOmitted) {
        return null;
    }

    return props.isRequired ? (
        <>
            <Box aria-hidden="true" as="span" ml={'2'}>
                *
            </Box>
            <VisuallyHidden>({__('Required')})</VisuallyHidden>
        </>
    ) : (
        <FormLabelOptional> ({__('Optional')})</FormLabelOptional>
    );
}

export function Field({
    field,
    error,
    register,
    setValue,
    clearErrors,
    setError,
    isMultiFieldGroup,
    isIntro,
}: FieldProps) {
    const isHidden = useIsHiddenInReview(field);
    const fieldLabel = useFieldTitleWithOverrides(field);
    const descriptiveText = getFieldDescriptiveText(field);

    return (
        <FormField
            style={isHidden ? { display: 'none' } : {}}
            mt={isMultiFieldGroup && !isIntro ? 6 : 0}
        >
            {/*
                ClientConfiguredField's manage more of their UI themselves including
                labels and error messages compared to all other field types. The exception here
                is error messages that get triggered by react-hook-form.
             */}
            {field.__typename !== 'ClientConfiguredField' && (
                <FormLabel
                    htmlFor={field.id}
                    fontSkin={
                        field.__typename === 'MultiGridChoiceField'
                            ? 'body-standard'
                            : 'body-standard-bold'
                    }
                    marginBottom={'3'}
                >
                    <span
                        dangerouslySetInnerHTML={{
                            __html: fieldLabel,
                        }}
                    />
                    <RequiredIndicator
                        isRequired={!!field.required}
                        isOmitted={field.__typename === 'WorkEntityField'}
                    />

                    {field.__typename === 'MultiGridChoiceField' && field.validationPrompt && (
                        <Typography fontSize="xsmall" as="span" ml={'2'}>
                            {`(${field.validationPrompt})`}
                        </Typography>
                    )}
                </FormLabel>
            )}
            {descriptiveText && (
                <Typography marginTop={'2'} marginBottom={'4'}>
                    {descriptiveText}
                </Typography>
            )}
            <FormInputGroup mt={isMultiFieldGroup || isIntro ? 0 : 6}>
                {error && (
                    <Box mb={'4'}>
                        <FormError id={`${field.id}+error`} marginTop={'4'}>
                            {error.message}
                        </FormError>
                    </Box>
                )}
                <FieldInput
                    field={field}
                    register={register}
                    setValue={setValue}
                    error={error}
                    clearErrors={clearErrors}
                    setError={setError}
                    aria-invalid={!!error}
                    aria-required={field.required}
                    aria-labelledby={field.id}
                    {...(error
                        ? {
                              'aria-describedby': `${field.id}+error`,
                              'aria-errormessage': `${field.id}+error`,
                          }
                        : {})}
                />
            </FormInputGroup>
        </FormField>
    );
}

type FieldInputProps = {
    field: FieldFragment;
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
    clearErrors: UseFormClearErrors<FormInput>;
    setError: UseFormSetError<FormInput>;
    error?: FieldError | undefined;
};

function FieldInput({ field, register, setValue, clearErrors, setError, error }: FieldInputProps) {
    switch (field.__typename) {
        case 'InputField':
            return (
                <InputField
                    {...field}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                />
            );
        case 'TextAreaField':
            return (
                <TextAreaField
                    {...field}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                />
            );
        case 'MultiChoiceField':
            return <MultiChoiceField {...field} register={register} setValue={setValue} />;
        case 'RadioChoiceField':
            return <RadioChoiceField {...field} register={register} setValue={setValue} />;
        case 'MultiInputField':
            return <MultiInputField {...field} register={register} setValue={setValue} />;
        case 'FilesField':
            return <FilesField {...field} register={register} setValue={setValue} />;
        case 'MultiGridChoiceField':
            return (
                <MultiGridChoiceField
                    {...field}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    setError={setError}
                />
            );
        case 'ClientConfiguredField':
            return (
                <ClientConfiguredField
                    {...field}
                    register={register}
                    setValue={setValue}
                    error={error}
                />
            );
        case 'WorkEntityField':
            return <WorkEntityField {...field} />;
        case 'SelectChoiceField':
            return <SelectChoiceField {...field} register={register} setValue={setValue} />;
        case 'GridChoiceField':
            return <GridChoiceField {...field} register={register} setValue={setValue} />;
        case 'DateField':
            return (
                <DateField
                    {...field}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                />
            );
        default:
            return <div>{field.__typename} not yet implemented</div>;
    }
}

type ColumnWidth = NonNullable<1 | 2 | 4 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>;

type FieldViewProps = {
    field: FieldFragment;
    skin?: FieldViewSkinType;
    colWidthOverride?: ColumnWidth;
};

export function FieldView({ field, colWidthOverride, skin }: FieldViewProps) {
    return isFieldShown(field) ? (
        <FieldDisplayWrapper title={getFieldLabel(field)} skin={skin}>
            <FieldDisplay field={field} colWidthOverride={colWidthOverride} />
        </FieldDisplayWrapper>
    ) : null;
}

/**
 * TODO: replace with additional schema fields.
 */
function reviewFieldTitleWithOverrides(field: FieldFragment): string {
    if (field.id === 'purpose') {
        return __('Use case');
    }

    return getFieldLabel(field);
}

function useFieldTitleWithOverrides(field: FieldFragment): string {
    const isReviewMode = useIsReviewMode();
    return isReviewMode ? reviewFieldTitleWithOverrides(field) : getFieldLabel(field);
}

export function FieldReview({
    field,
    colWidthOverride,
    showTitle,
    ...props
}: FieldViewProps & { showTitle?: boolean }) {
    const skin = useFieldViewSkin(props.skin);
    const viewContext = useViewContext();
    return isFieldShown(field, viewContext) ? (
        <Box mt="5">
            {showTitle && (
                <Typography fontSkin="body-standard-bold" pb={'3'}>
                    {reviewFieldTitleWithOverrides(field)}
                </Typography>
            )}
            <FieldViewSkinContext.Provider value={skin}>
                <FieldReviewDisplay field={field} colWidthOverride={colWidthOverride} />
            </FieldViewSkinContext.Provider>
        </Box>
    ) : null;
}

interface FieldDisplayProps {
    field: FieldFragment;
    colWidthOverride?: ColumnWidth;
}

export function FieldReviewDisplay({ field, colWidthOverride }: FieldDisplayProps) {
    switch (field.__typename) {
        case 'GridChoiceField':
            return <GridChoiceFieldReview colWidthOverride={colWidthOverride} {...field} />;
        case 'MultiGridChoiceField':
            return <MultiGridChoiceFieldReview colWidthOverride={colWidthOverride} {...field} />;
        case 'FilesField':
            return <FilesFieldReview {...field} />;
        case 'ClientConfiguredField':
            return <ClientConfiguredFieldReview {...field} />;
        default:
            return <FieldDisplay field={field} colWidthOverride={colWidthOverride} />;
    }
}

export function FieldDisplay({ field, colWidthOverride }: FieldDisplayProps) {
    switch (field.__typename) {
        case 'InputField':
            return <InputFieldView inputValue={field.inputValue} />;
        case 'TextAreaField':
            return <TextAreaFieldView textAreaValue={field.textAreaValue} />;
        case 'SelectChoiceField':
            return (
                <SelectChoiceFieldView options={field.options} choiceValue={field.choiceValue} />
            );
        case 'RadioChoiceField':
            return <RadioChoiceFieldView options={field.options} choiceValue={field.choiceValue} />;
        case 'MultiChoiceField':
            return (
                <MultiChoiceFieldView
                    options={field.options}
                    multiChoiceValue={field.multiChoiceValue}
                />
            );
        case 'MultiInputField':
            return <MultiInputFieldView multiInputValue={field.multiInputValue} />;
        case 'WorkEntityField':
            return <WorkEntityField {...field} />;
        case 'MultiGridChoiceField':
            return <MultiGridChoiceFieldView colWidthOverride={colWidthOverride} {...field} />;
        case 'FilesField':
            return <FilesFieldView {...field} />;
        case 'GridChoiceField':
            return <GridChoiceFieldView colWidthOverride={colWidthOverride} {...field} />;
        case 'DateField':
            return <DateFieldView dateValue={field.dateValue} />;
        default:
            return <div>{field.__typename} not yet implemented</div>;
    }
}

export const FieldSet = styled.fieldset`
    border: none;
    margin: 0 ${tokensRaw.SwanSemSpace5} ${tokensRaw.SwanSemSpace7};
    padding: 0 0;
`;

interface FieldDisplayWrapperProps extends PropsWithChildren {
    title: string;
    skin?: FieldViewSkinType;
}

export function FieldDisplayWrapper(props: FieldDisplayWrapperProps) {
    const skin = useFieldViewSkin(props.skin);

    return (
        <FieldViewSkinContext.Provider value={skin}>
            <FieldSet>
                <Typography fontSize={skin.fontSize} fontWeight="bold" pb={'3'}>
                    {props.title}
                </Typography>
                {props.children}
            </FieldSet>
        </FieldViewSkinContext.Provider>
    );
}

export function isFieldShown(field: FieldFragment, viewContext?: ViewContextProps): boolean {
    switch (field.__typename) {
        case 'InputField':
            return !!field.inputValue;
        case 'TextAreaField':
            return !!field.textAreaValue;
        case 'SelectChoiceField':
        case 'RadioChoiceField':
            return !!field.choiceValue;
        case 'MultiChoiceField':
            return field.multiChoiceValue.length > 0;
        case 'MultiInputField':
            return field.multiInputValue.length > 0;
        case 'WorkEntityField':
            return !!field.workEntityValue;
        case 'MultiGridChoiceField':
            return field.multiGridChoiceValue.length > 0;
        case 'FilesField':
            return field.filesValue.length > 0;
        case 'GridChoiceField':
            return !!field.choiceValue;
        case 'DateField':
            return !!field.dateValue;
        case 'ClientConfiguredField':
            return (
                viewContext?.isReviewMode === true &&
                ['appointmentTime', 'productOptions'].includes(field.id)
            );
        default:
            return false;
    }
}

function getFieldLabel(field: FieldFragment): string {
    if (field.__typename !== 'ClientConfiguredField' && field.__typename !== 'WorkEntityField') {
        return field.label;
    }

    return '';
}

function getFieldDescriptiveText(field: FieldFragment): string {
    if (field.__typename === 'FilesField' || field.__typename === 'InputField') {
        return field.descriptiveText || '';
    }

    return '';
}
