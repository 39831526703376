/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';
import { BriefRequestFragment } from '../../../brief.generated';

export type SelectProductMutationVariables = Types.Exact<{
    requestId: Types.Scalars['ID'];
    input: Types.SelectProductEventInput;
}>;

export type SelectProductMutation = {
    __typename: 'Mutation';
    addEvent_SelectProduct: { __typename: 'Request' } & BriefRequestFragment;
};

export const SelectProductDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'SelectProduct' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'requestId' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SelectProductEventInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addEvent_SelectProduct' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'requestId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'requestId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'BriefRequest' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'BriefRequest' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Request' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'facts' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'designLiveAppointmentTime' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'designLiveTimezone' },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expertService' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'productDetails' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'inclusions' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'serviceProduct' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mpvId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'productKey' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'pimMerchantVersion' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'printProduct' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mpvId' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'currency' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'listPrice' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'taxed' },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type SelectProductMutationFn = Apollo.MutationFunction<
    SelectProductMutation,
    SelectProductMutationVariables
>;

/**
 * __useSelectProductMutation__
 *
 * To run a mutation, you first call `useSelectProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectProductMutation, { data, loading, error }] = useSelectProductMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectProductMutation(
    baseOptions?: Apollo.MutationHookOptions<SelectProductMutation, SelectProductMutationVariables>
) {
    return Apollo.useMutation<SelectProductMutation, SelectProductMutationVariables>(
        SelectProductDocument,
        baseOptions
    );
}
export type SelectProductMutationHookResult = ReturnType<typeof useSelectProductMutation>;
export type SelectProductMutationResult = Apollo.MutationResult<SelectProductMutation>;
export type SelectProductMutationOptions = Apollo.BaseMutationOptions<
    SelectProductMutation,
    SelectProductMutationVariables
>;
