import { PrintProductOption } from '@99designs/graph-utils/types';

export default function findProductOptions(
    selectedOptions: Record<string, string>,
    productOptions: PrintProductOption[]
): PrintProductOption[] {
    return Object.entries(selectedOptions)
        .map(([key, value]) => {
            const matchedOption = productOptions.find((option) => option.key === key);
            if (matchedOption) {
                const matchedValue = matchedOption.values.find((val) => val.key === value);
                if (matchedValue) {
                    return {
                        ...matchedOption,
                        values: [matchedValue],
                    };
                }
            }
            return null;
        })
        .filter((option): option is PrintProductOption => option !== null);
}
